import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser } from './reducers/authReducer';
import { logoutUser } from './actions/authAction';

import axios from 'axios'
import { API_URL } from './actions/constant'


import { Provider } from 'react-redux';
import store from './store';
import './App.css';
import PrivateRoute from './components/common/PrivateRoute';
import { listSetting, setlanguage } from './reducers/crudReducer';

import Loader from "./components/common/Spinner"
import { Language } from './actions/language';

axios.defaults.baseURL = API_URL;



const Landing = React.lazy(() => import('./components/layouts/Landing'));


//ADMIN ROUTE
const AdminLogin = React.lazy(() => import('./components/admin/auth/Login'));
const AdminVerifyOtp = React.lazy(() => import('./components/admin/auth/VerifyOtp'));



const Dashboard = React.lazy(() => import('./components/admin/dashboard/Dashboard'));

const ListUser = React.lazy(() => import('./components/admin/user/list/List'));
const ViewUser = React.lazy(() => import('./components/admin/user/view/View'));

const ListSlider = React.lazy(() => import('./components/admin/slider/list/List'));
const AddSlider = React.lazy(() => import('./components/admin/slider/add'));
const ViewSlider = React.lazy(() => import('./components/admin/slider/view/'));


const ListCategory = React.lazy(() => import('./components/admin/category/list/List'));
const AddCategory = React.lazy(() => import('./components/admin/category/add'));
const ViewCategory = React.lazy(() => import('./components/admin/category/view/'));

const ListListingType = React.lazy(() => import('./components/admin/listingtype/list/List'));
const AddListingType = React.lazy(() => import('./components/admin/listingtype/add'));
const ViewListingType = React.lazy(() => import('./components/admin/listingtype/view/'));

const ListFeature = React.lazy(() => import('./components/admin/feature/list/List'));
const AddFeature = React.lazy(() => import('./components/admin/feature/add'));
const ViewFeature = React.lazy(() => import('./components/admin/feature/view/'));

const ListPlan = React.lazy(() => import('./components/admin/plan/list/List'));
const AddPlan = React.lazy(() => import('./components/admin/plan/add'));
const ViewPlan = React.lazy(() => import('./components/admin/plan/view/'));

const ListListing = React.lazy(() => import('./components/admin/listing/list/List'));
const ViewListing = React.lazy(() => import('./components/admin/listing/view/View'));

const ListPaymentHistory = React.lazy(() => import('./components/admin/paymenthistory/list/List'));


const AddSetting = React.lazy(() => import('./components/admin/setting/AddSetting'));


const ListNotification = React.lazy(() => import('./components/admin/notification/list/List'));
const AddNotification = React.lazy(() => import('./components/admin/notification/add'));
const ViewNotification = React.lazy(() => import('./components/admin/notification/view/'));



const PaymentRedirect = React.lazy(() => import('./components/admin/payment/Redirect'));

const Privacy = React.lazy(() => import('./components/admin/privacy/'));
const Terms = React.lazy(() => import('./components/admin/terms/'));


store.dispatch(listSetting())



if (!localStorage.lang) {
  axios.defaults.params = { lang: "en" };
  axios.defaults.headers.common['lang'] = "en";
  store.dispatch(setlanguage(Language["en"]))
} else {
  axios.defaults.params = { lang: localStorage.lang };
  axios.defaults.headers.common['lang'] = localStorage.lang
  store.dispatch(setlanguage(Language[localStorage.lang]))
}











if (localStorage.jwtToken) {
  //Set Auth Token header Auth
  setAuthToken(localStorage.jwtToken);
  //Decode Token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  store.dispatch(setCurrentUser(decoded));
  //Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    //Logout user
    store.dispatch(logoutUser());
    //ToDO : clear current profile
    //Redirect to Login page
    window.location.href = '/login';
  }

}




export default function App() {

  var currentlayout = localStorage.lang === "ar" ? "rtl" : "ltr"

  return (
    <div dir={currentlayout}>


      <Provider store={store}>
        <Router
          exact
          path="/"
        >
          <Suspense fallback={<Loader />}>
            {/* <div class="nk-app-root"> */}
            {/* ADMIN ROUTE */}
            <Routes>
              <Route index exact path="/" element={<AdminLogin />} />
              <Route exact path="/payment/redirect" element={<PaymentRedirect />} />
              <Route exact path="/privacy" element={<Privacy />} />
              <Route exact path="/terms" element={<Terms />} />


              {/* //ADMIN ROUTE */}
              <Route index exact path="/admin/login" element={<AdminLogin />} />

              <Route index exact path="/admin/verifyotp" element={<AdminVerifyOtp />} />

              <Route path="/" element={<PrivateRoute />}>
                <Route index exact path="/admin/dashboard" element={<Dashboard />} />

                <Route index exact path="/admin/user/list" element={<ListUser />} />
                <Route index exact path="/admin/user/view/:id" element={<ViewUser />} />

                <Route index exact path="/admin/slider/list" element={<ListSlider />} />
                <Route index exact path="/admin/slider/add" element={<AddSlider />} />
                <Route index exact path="/admin/slider/view/:id" element={<ViewSlider />} />

                <Route index exact path="/admin/category/list" element={<ListCategory />} />
                <Route index exact path="/admin/category/add" element={<AddCategory />} />
                <Route index exact path="/admin/category/view/:id" element={<ViewCategory />} />

                <Route index exact path="/admin/listingtype/list" element={<ListListingType />} />
                <Route index exact path="/admin/listingtype/add" element={<AddListingType />} />
                <Route index exact path="/admin/listingtype/view/:id" element={<ViewListingType />} />

                <Route index exact path="/admin/feature/list" element={<ListFeature />} />
                <Route index exact path="/admin/feature/add" element={<AddFeature />} />
                <Route index exact path="/admin/feature/view/:id" element={<ViewFeature />} />

                <Route index exact path="/admin/plan/list" element={<ListPlan />} />
                <Route index exact path="/admin/plan/add" element={<AddPlan />} />
                <Route index exact path="/admin/plan/view/:id" element={<ViewPlan />} />

                <Route index exact path="/admin/listing/list" element={<ListListing />} />
                <Route index exact path="/admin/listing/view/:id" element={<ViewListing />} />

                <Route index exact path="/admin/paymenthistory/list" element={<ListPaymentHistory />} />


                <Route index exact path="/admin/notification/list" element={<ListNotification />} />
                <Route index exact path="/admin/notification/add" element={<AddNotification />} />
                <Route index exact path="/admin/notification/view/:id" element={<ViewNotification />} />


                <Route index exact path="/admin/setting/" element={<AddSetting />} />

              </Route>



            </Routes>
            {/* </div> */}
          </Suspense>
        </Router>
      </Provider>
    </div>
  )
}
