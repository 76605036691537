import React from "react"
export const Language = {
    en: {
        welcome: "Welcome",
        logout: "Logout",
        subtitle: "At a glance summary of your account.",
        recentList: "Recent Listing",
        recentPayment: "Recent Payments",
        havetotal: "You have total",
        records: "Records",
        goback: "Go Back",
        exportcsv: "Export CSV",
        view: "View",
        personalinfo: "Personal Information",
        selectuserstatus: "Select User Status",
        fullName: "Full Name",
        email: "Email",
        mobile: "Mobile",
        delete: "Delete",
        filter: "Filter",
        fromDate: "From Date",
        toDate: "To Date",
        viewedit: "View/Edit",
        addNew: "Add New",
        saveChanges: "Save Changes",
        fillAll: "Fill all the required Fields",
        submit: "Submit",
        reset: "Reset",
        appName: "Application Name",
        appLogo: "Application Logo",
        favicon: "Favicon",
        adminLogo: "Admin Logo",
        appemail: "Application Email",
        address: "Contact Address",
        instagramLink: "Instagram Link",
        facebookLink: "Facebook Link",
        youtubeLink: "Youtube Link",
        authTitle: "Auth Title",
        authInfo: "For Mobile App Login Screens",
        authTitle_ar: "Auth Title Arabic",
        authSubTitle: "Auth Subtitle",
        authSubTitle_ar: "Auth Subtitle Arabic",
        freePost: "Free Ads",
        freeExpiryDays: "Free Ads Expiry Days",
        privacyLink: "Privacy Policy Link",
        termsLink: "Terms & Condition",
        update: "Update",
        paymentGateway: "Payment GateWay Setup",
        paymentClient: "Client Key",
        paymentSecret: "Client Secret Key",
        paymentMode: "Mode",
        updatePassword: "Update Password",
        newpassword: "New Password",
        newpassword1: "Confirm Password",
        siteSetting: "Site Setting",
        //PROEPRTY INFO
        propertyInfo: "Property Information",
        basics: "Basics",
        listingType: "Listing Type",
        category: "Category",
        propertyTitle: "Property Title",
        propertyLocation: "Property Location",
        country: "Country",
        viewOnMap: "View on Map",
        rentPrice: "Rent Price",
        featuredAd: "Is Featured Ad ?",
        imageGallery: "Image & Video Gallery",
        propertyFeatures: "Property Features",
        video: "Video",
        images: "Images",
        features: "Features",
        yes: "Yes",
        no: "No",
        loadingTxt: "Loading....",
        //Toast Status
        userUpdate: "User Status Updated",
        errorTxt: "Error Occured Try Again",
        recordAdded: "Record Added Successfully",
        recordEdit: "Record Edited Successfully",
        recordDelete: "Record Deleted Successfully",
        propertyStatus: "Property Status Updated",
        notificationText: "Notification Sent Successfully",
        fileUploaded: "File Uploaded Successfully",
        settingUpdate: "Setting Updated Successfully",
        passwordUpdate: "Password Updated Successfully",
        //LOGIN PAGE
        emailAddress: "Email Address",
        password: "Password",
        signIn: "Sign in",
        adminSignIn: "Admin Sign-In",
        accessThe: "Access the",
        accessSub: "panel using your email and passcode.",
        otpverification: "OTP Verification",
        otpSub: "Enter the verification code we sent to",
        verifyOtp: "Verify OTP",
        didntget: "Didn’t get the code ?",
        resend: "Resend",
        otpResend: 'OTP Sent Successfully',
        enterOTP: "Enter the OTP"

    },
    ar: {
        welcome: "مرحباً",
        logout: "تسجيل خروج",
        subtitle: "في لمحة ملخص حسابك.",
        recentList: "القائمة الأخيرة",
        recentPayment: "المدفوعات الأخيرة",
        havetotal: "لديك المجموع",
        records: "السجلات",
        goback: "عُد",
        exportcsv: "تصدير CSV",
        view: "منظر",
        personalinfo: "معلومات شخصية",
        selectuserstatus: "حدد حالة المستخدم",
        fullName: "الاسم الكامل",
        email: "بريد إلكتروني",
        mobile: "متحرك",
        delete: "يمسح",
        filter: "منقي",
        fromDate: "من التاريخ",
        toDate: "ان يذهب في موعد",
        viewedit: "معاينة ما تم تحريره",
        addNew: "اضف جديد",
        saveChanges: "حفظ التغييرات",
        fillAll: "املأ جميع الحقول المطلوبة",
        submit: "يُقدِّم",
        reset: "إعادة ضبط",
        appName: "اسم التطبيق",
        appLogo: "شعار التطبيق",
        favicon: "فافيكون",
        adminLogo: "شعار المسؤول",
        appemail: "البريد الإلكتروني للتطبيق",
        address: "عنوان الإتصال",
        instagramLink: "رابط Instagram",
        facebookLink: "رابط الفيسبوك",
        youtubeLink: "رابط يوتيوب",
        authTitle: "عنوان المصادقة",
        authInfo: "لشاشات تسجيل الدخول إلى تطبيقات الجوال",
        authTitle_ar: "عنوان العنوان العربي",
        authSubTitle: "الترجمة الفرعية",
        authSubTitle_ar: "الترجمة الفرعية العربية",
        freePost: "إعلانات مجانية",
        freeExpiryDays: "إعلانات مجانية أيام انتهاء الصلاحية",
        privacyLink: "رابط سياسة الخصوصية",
        termsLink: "الشروط والشرط",
        update: "تحديث",
        paymentGateway: "إعداد بوابة الدفع",
        paymentClient: "مفتاح العميل",
        paymentSecret: "المفتاح السري للعميل",
        paymentMode: "وضع",
        updatePassword: "تطوير كلمة السر",
        newpassword: "كلمة المرور الجديدة",
        newpassword1: "تأكيد كلمة المرور",
        siteSetting: "إعداد الموقع",
        //PROEPRTY INFO
        propertyInfo: "معلومات الملكية",
        basics: "الأساسيات",
        listingType: "نوع القائمة",
        category: "فئة",
        propertyTitle: "عنوان الممتلكات",
        propertyLocation: "موقع العقار",
        country: "دولة",
        viewOnMap: "عرض على الخريطة",
        rentPrice: "سعر الإيجار",
        featuredAd: "هل الإعلان مميز؟",
        imageGallery: "معرض الصور والفيديو",
        propertyFeatures: "خصائص المنشأة",
        video: "فيديو",
        images: "الصور",
        features: "سمات",
        yes: "نعم",
        no: "لا",
        loadingTxt: "تحميل....",
        userUpdate: "تحديث حالة المستخدم",
        errorTxt: "حدث خطأ حاول مرة أخرى",
        recordAdded: "سجل أضيفت بنجاح",
        recordEdit: "سجل التحرير بنجاح",
        recordDelete: "سجل حذف بنجاح",
        propertyStatus: "تم تحديث حالة الخاصية",
        notificationText: "إرسال الإخطار بنجاح",
        fileUploaded: "تم رفع الملف بنجاح",
        settingUpdate: "ضبط التحديث بنجاح",
        passwordUpdate: "تم تحديث كلمة السر بنجاح",
        //LOGIN PAGE
        emailAddress: "عنوان البريد الإلكتروني",
        password: "كلمة المرور",
        signIn: "تسجيل الدخول",
        adminSignIn: "تسجيل الدخول",
        accessThe: "الوصول إلى",
        accessSub: "لوحة تستخدم بريدك الإلكتروني ورمز المرور.",
        otpverification: "التحقق من OTP",
        otpSub: "أدخل رمز التحقق الذي أرسلناه إليه",
        verifyOtp: "تحقق من OTP",
        didntget: "لم تحصل على الرمز؟",
        resend: "إعادة إرسال",
        otpResend: 'أرسل OTP بنجاح',
        enterOTP: "أدخل OTP"




    }
}